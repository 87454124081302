

































































import { ConfirmModal } from '@/app/components';
import store from '@/app/store';
import { ChevronLeftIcon, XIcon } from '@vue-hero-icons/outline';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import { WizardAction } from '../types';

export default defineComponent({
    name: 'WizardActions',
    components: { ConfirmModal, XIcon, ChevronLeftIcon },
    props: {
        backTo: {
            type: String,
            default: 'home',
        },
        actions: {
            type: Array as PropType<WizardAction[]>,
            required: true,
        },
        showCancel: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any, { root, emit }: { root: any; emit: any }) {
        const showConfirmCancelModal = ref(false);

        const cancel = () => {
            if (props.backTo === 'data-checkin-jobs')
                root.$router.push({ name: props.backTo, query: store.state.queryParams.jobs });
            else root.$router.push({ name: props.backTo });
            emit('cancel');
        };

        return { showConfirmCancelModal, cancel };
    },
});
