







































import { FileDropzone, FileWithIcon, FormBlock } from '@/app/components';
import store from '@/app/store';
import { useAxios } from '@vue-composable/axios';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { ApolloAPI } from '../../../api';
import { ApolloTaskShell } from '../../../components';
import { useApolloTask, usePolicyFileUpload } from '../../../composable';
import { TaskStatus, WorkflowStatus } from '../../../constants';
import { ApolloTask, OtherFileHarvesterConfiguration, TFile, WizardAction } from '../../../types';
import { FilesUpload } from '../common';

export default defineComponent({
    name: 'OtherFileHarvester',
    props: {
        task: {
            type: Object as PropType<ApolloTask<OtherFileHarvesterConfiguration>>,
            required: true,
        },
    },
    components: { FileDropzone, FilesUpload, FormBlock, FileWithIcon, ApolloTaskShell },
    setup(props, { root }) {
        const { exec } = useAxios(true);
        const dropzoneRef = ref<any>(null);
        const showFinalizeModal = ref<boolean>(false);
        const task = ref<ApolloTask<OtherFileHarvesterConfiguration>>(props.task);

        const { inDraftStatus, inUpdateStatus, loading: loadingTask, isFinalized, save, finalize } = useApolloTask(
            task,
        );

        const files = ref<TFile[]>([]);
        const loadingOtherFileHarvester = ref(false);

        const loading = computed(() => loadingTask.value || loadingOtherFileHarvester.value);

        const { uploadPolicyFiles, fileProgress } = usePolicyFileUpload(props.task.pipeline.id);

        const canBeFinalized = computed(() => files.value.length > 0);

        const dropzoneDisabled = computed(
            () =>
                loading.value ||
                (props.task.pipeline.status !== WorkflowStatus.Ready && props.task.status === TaskStatus.Ready),
        );

        const canAppendFiles = computed(() => !!files.value.length);

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            { key: 'append', show: !!task.value?.canAddFiles, enabled: !!canAppendFiles.value },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: task.value?.status !== TaskStatus.Deprecated && canBeFinalized.value,
            },
        ]);

        const uploadFiles = async () => {
            const subfolder = new Date().valueOf().toString();
            const data = files.value.map((file: TFile) => ({ file, policy: { folder: 'upload', subfolder } }));

            await uploadPolicyFiles(data);
        };

        const finalizeTask = async () => {
            if (!canBeFinalized.value) return;
            try {
                await save();
                loadingOtherFileHarvester.value = true;
                await uploadFiles();
                loadingOtherFileHarvester.value = false;
                await finalize();
                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            } finally {
                loadingOtherFileHarvester.value = false;
            }
        };

        const removeFile = (file: TFile) => {
            if (!dropzoneRef.value) return;
            dropzoneRef.value.removeFile(file);
        };

        const appendFiles = async () => {
            try {
                loadingOtherFileHarvester.value = true;
                await uploadFiles();
                await exec(ApolloAPI.run(props.task?.pipeline.id as string));
                root.$router.push({ name: 'data-checkin-jobs', query: store.state.queryParams.jobs });
            } catch {
                (root as any).$toastr.e('Failed to append files', 'An error occurred!');
            } finally {
                loadingOtherFileHarvester.value = false;
            }
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<OtherFileHarvesterConfiguration>) => {
                task.value = newTask;
            },
        );

        return {
            dropzoneRef,
            inDraftStatus,
            inUpdateStatus,
            canBeFinalized,
            files,
            fileProgress,
            finalizeTask,
            removeFile,
            appendFiles,
            dropzoneDisabled,
            canAppendFiles,
            loading,
            showFinalizeModal,
            wizardActions,
        };
    },
});
