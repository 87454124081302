












import { useAxios } from '@/app/composable';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { ApolloAPI } from '../api';
import { APIHarvester, ReviewAPIHarvester, SetupAPIHarvester } from '../components/harvesters/api-harvester';
import {
    BigQueryHarvester,
    ReviewBigQueryHarvester,
    SetupBigQueryHarvester,
} from '../components/harvesters/big-query-harvester';
import {
    ExternalKafkaHarvester,
    ReviewExternalKafkaHarvester,
    SetupExternalKafkaHarvester,
} from '../components/harvesters/external-kafka-harvester';
import {
    ExternalMqttHarvester,
    ReviewExternalMqttHarvester,
    SetupExternalMqttHarvester,
} from '../components/harvesters/external-mqtt-harvester';
import { FileHarvester, ReviewFileHarvester, SetupFileHarvester } from '../components/harvesters/file-harvester';
import {
    InternalApiHarvester,
    ReviewInternalApiHarvester,
    SetupInternalApiHarvester,
} from '../components/harvesters/internal-api-harvester';
import { KafkaHarvester, ReviewKafkaHarvester, SetupKafkaHarvester } from '../components/harvesters/kafka-harvester';
import {
    LargeFilesHarvester,
    ReviewLargeFilesHarvester,
    SetupLargeFilesHarvester,
} from '../components/harvesters/large-files-harvester';
import { MqttHarvester, ReviewMqttHarvester, SetupMqttHarvester } from '../components/harvesters/mqtt-harvester';
import { OtherFileHarvester } from '../components/harvesters/other-file-harvester';
import {
    ReviewSAPHanaHarvester,
    SAPHanaHarvester,
    SetupSAPHanaHarvester,
} from '../components/harvesters/sap-hana-harvester';
import { ReviewSQLHarvester, SQLHarvester, SetupSQLHarvester } from '../components/harvesters/sql-harvester';
import { HarvesterBlockId } from '../constants';
import { ApolloTask, HarvesterConfiguration, HarvesterStep, HarvesterTask } from '../types';

export default defineComponent({
    name: 'Harvester',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    components: { FileHarvester, APIHarvester, OrbitSpinner },
    metaInfo() {
        return { title: `Configure Harvester` };
    },
    setup(props) {
        const { loading, exec } = useAxios(true);
        const task = ref<ApolloTask<HarvesterConfiguration>>();

        const harvesters: Record<string, HarvesterTask> = {
            [HarvesterBlockId.File]: {
                blockId: HarvesterBlockId.File,
                component: FileHarvester,
                steps: [
                    { key: HarvesterStep.Setup, name: 'Setup Harvest Service', component: SetupFileHarvester },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewFileHarvester,
                    },
                ],
            },
            [HarvesterBlockId.OtherFile]: {
                blockId: HarvesterBlockId.OtherFile,
                component: OtherFileHarvester,
                steps: [],
            },
            [HarvesterBlockId.Api]: {
                blockId: HarvesterBlockId.Api,
                component: APIHarvester,
                steps: [
                    {
                        key: HarvesterStep.Setup,
                        name: 'Setup Harvest Service',
                        component: SetupAPIHarvester,
                    },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewAPIHarvester,
                    },
                ],
            },
            [HarvesterBlockId.Kafka]: {
                blockId: HarvesterBlockId.Kafka,
                component: KafkaHarvester,
                steps: [
                    {
                        key: HarvesterStep.Setup,
                        name: 'Setup Harvest Service',
                        component: SetupKafkaHarvester,
                    },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewKafkaHarvester,
                    },
                ],
            },
            [HarvesterBlockId.ExternalKafka]: {
                blockId: HarvesterBlockId.ExternalKafka,
                component: ExternalKafkaHarvester,
                steps: [
                    {
                        key: HarvesterStep.Setup,
                        name: 'Setup Harvest Service',
                        component: SetupExternalKafkaHarvester,
                    },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewExternalKafkaHarvester,
                    },
                ],
            },
            [HarvesterBlockId.MQTT]: {
                blockId: HarvesterBlockId.MQTT,
                component: MqttHarvester,
                steps: [
                    {
                        key: HarvesterStep.Setup,
                        name: 'Setup Harvest Service',
                        component: SetupMqttHarvester,
                    },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewMqttHarvester,
                    },
                ],
            },
            [HarvesterBlockId.ExternalMQTT]: {
                blockId: HarvesterBlockId.ExternalMQTT,
                component: ExternalMqttHarvester,
                steps: [
                    {
                        key: HarvesterStep.Setup,
                        name: 'Setup Harvest Service',
                        component: SetupExternalMqttHarvester,
                    },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewExternalMqttHarvester,
                    },
                ],
            },
            [HarvesterBlockId.LargeFiles]: {
                blockId: HarvesterBlockId.LargeFiles,
                component: LargeFilesHarvester,
                steps: [
                    { key: HarvesterStep.Setup, name: 'Setup Harvest Service', component: SetupLargeFilesHarvester },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewLargeFilesHarvester,
                    },
                ],
            },
            [HarvesterBlockId.InternalApi]: {
                blockId: HarvesterBlockId.InternalApi,
                component: InternalApiHarvester,
                steps: [
                    {
                        key: HarvesterStep.Setup,
                        name: 'Setup Harvest Service',
                        component: SetupInternalApiHarvester,
                    },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewInternalApiHarvester,
                    },
                ],
            },
            [HarvesterBlockId.BigQuery]: {
                blockId: HarvesterBlockId.BigQuery,
                component: BigQueryHarvester,
                steps: [
                    {
                        key: HarvesterStep.Setup,
                        name: 'Setup Harvest Service',
                        component: SetupBigQueryHarvester,
                    },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewBigQueryHarvester,
                    },
                ],
            },
            [HarvesterBlockId.SQL]: {
                blockId: HarvesterBlockId.SQL,
                component: SQLHarvester,
                steps: [
                    {
                        key: HarvesterStep.Setup,
                        name: 'Setup Harvest Service',
                        component: SetupSQLHarvester,
                    },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewSQLHarvester,
                    },
                ],
            },
            [HarvesterBlockId.SAPHana]: {
                blockId: HarvesterBlockId.SAPHana,
                component: SAPHanaHarvester,
                steps: [
                    {
                        key: HarvesterStep.Setup,
                        name: 'Setup Harvest Service',
                        component: SetupSAPHanaHarvester,
                    },
                    {
                        key: HarvesterStep.Review,
                        name: 'Test and Review Configuration',
                        component: ReviewSAPHanaHarvester,
                    },
                ],
            },
        };

        const harvester = computed(() => (task.value ? harvesters[task.value.blockId] : undefined));

        watch(
            () => props.id,
            () => {
                exec(ApolloAPI.getTask(props.id, 'harvester')).then((res) => {
                    task.value = res?.data;
                });
            },
            { immediate: true },
        );

        return {
            task,
            harvester,
            loading,
        };
    },
});
