



































import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ApolloTaskShell } from '../../../components';
import { useApolloTask, usePolicyFileUpload } from '../../../composable';
import {
    ApolloTask,
    ExternalMqttHarvesterConfiguration,
    HarvesterStep,
    TFile,
    TaskStep,
    WizardAction,
} from '../../../types';

export default defineComponent({
    name: 'ExternalMqttHarvester',
    props: {
        task: {
            type: Object as PropType<ApolloTask<ExternalMqttHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { ApolloTaskShell },
    setup(props, { root }) {
        const showFinalizeModal = ref<boolean>(false);
        const finalizing = ref<boolean>(false);
        const currentStep = ref<HarvesterStep>(HarvesterStep.Setup);
        const sampleFile = ref<TFile | null>(null);
        const sample = ref<any>(props.task.processedSample);
        const finalSample = ref<any>(props.task.processedSample);
        const sampleToBeUploaded = ref<any>(props.task.processedSample);
        const initialEndDate = ref<Date>(R.clone(props.task.configuration.retrieval.endDate));
        const initialParameter = ref<string | null>(R.clone(props.task.configuration.topicNameField));
        const basePath = 'res';
        const separator = '||';

        const { uploadSampleFileFromData } = usePolicyFileUpload(props.task.pipeline.id);
        const taskRef = ref<ApolloTask<ExternalMqttHarvesterConfiguration>>(props.task);
        const {
            loading: loadingTask,
            isFinalized,
            inUpdateStatus,
            pipelineFinalized,
            finalize,
            save,
            shouldClearHarvesterProcessedSample,
        } = useApolloTask<ExternalMqttHarvesterConfiguration>(taskRef);

        const finalLoading = computed(() => props.loading || loadingTask.value);

        const loadingFinalization = computed(() => showFinalizeModal.value || finalizing.value);

        const endDateChanged = computed(() => initialEndDate.value !== taskRef.value?.configuration.retrieval.endDate);

        const isSampleArray = computed(() => (sample.value ? R.is(Array, sample.value) : false));

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'save-after-finalize',
                show: isFinalized.value,
                enabled: endDateChanged.value,
                showCancel: isFinalized.value && endDateChanged.value,
            },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled:
                    currentStep.value === HarvesterStep.Review &&
                    !!taskRef.value &&
                    taskRef.value.configuration.response.selectedItems.length > 0,
            },
        ]);

        const setUploadedSample = (parsedSample: any) => {
            sample.value = parsedSample;
            sampleToBeUploaded.value = parsedSample;
        };

        const modifyFinalSample = (sampleData: any) => {
            finalSample.value = sampleData;
        };

        const setSampleFile = (file: TFile | null) => {
            sampleFile.value = file;
            if (!file) sample.value = null;
        };

        const saveTask = async () => {
            try {
                const shouldClearProcessedSample = await shouldClearHarvesterProcessedSample();
                const res = await save(shouldClearProcessedSample);
                initialEndDate.value = res.configuration.retrieval.endDate;
                (root as any).$toastr.s('Harvester configuration saved successfully', 'Success');
            } catch (e) {
                (root as any).$toastr.e('Failed to save task', 'An error occurred!');
            }
        };

        const modifySample = (sampleData: any) => {
            sampleToBeUploaded.value = sampleData;
        };

        const setSelectedItems = (items: string[]) => {
            if (!taskRef.value) return;
            taskRef.value.configuration.response.selectedItems = items;
        };

        const setParameterName = (parameterName: string | null) => {
            if (!taskRef.value) return;

            taskRef.value.configuration.topicNameField = parameterName;

            // Set the topic parameter value manually as 'SUB_TOPIC'
            taskRef.value.configuration.response.additional = parameterName
                ? [{ key: parameterName, value: 'SUB_TOPIC', type: 'static' }]
                : [];
        };

        const finalizeTask = async () => {
            if (!taskRef.value) return;

            try {
                finalizing.value = true;

                // save configuration and sample
                taskRef.value.configuration.sample = R.is(Array, finalSample.value)
                    ? finalSample.value
                    : [finalSample.value];
                taskRef.value.configuration.response.basePath = 'res';
                taskRef.value.configuration.response.multiple = isSampleArray.value;

                const newParameter = taskRef.value.configuration.topicNameField;
                const selected = taskRef.value.configuration.response.selectedItems;
                const prefix = `${basePath}${isSampleArray.value ? '[0]' : ''}${separator}`;

                // if parameter changed set it in selected items
                if (initialParameter.value !== newParameter) {
                    // remove old parameter from selected items
                    if (initialParameter.value && selected.includes(`${prefix}${initialParameter.value}`))
                        selected.splice(selected.indexOf(`${prefix}${initialParameter.value}`), 1);

                    // add new parameter to selected items
                    if (newParameter && !selected.includes(`${prefix}${newParameter}`))
                        selected.push(`${prefix}${newParameter}`);
                }
                // if parameter remained the same (e.g. when cloning)
                else if (newParameter) {
                    // add it to sample if not already exists
                    if (isSampleArray.value) {
                        sampleToBeUploaded.value.forEach((row: any) => {
                            if (!Object.keys(row).includes(newParameter)) row[newParameter] = 'SUB_TOPIC';
                        });
                    } else {
                        if (!Object.keys(sampleToBeUploaded.value).includes(newParameter))
                            sampleToBeUploaded.value[newParameter] = 'SUB_TOPIC';
                    }
                    // add it to selected items if not already exists
                    if (!selected.includes(`${prefix}${newParameter}`)) selected.push(`${prefix}${newParameter}`);
                }

                await save();

                // upload sample to minio
                await uploadSampleFileFromData({
                    data: JSON.stringify(sampleToBeUploaded.value),
                    name: 'sample.json',
                    type: 'application/json',
                    policy: { folder: 'upload', subfolder: `sample/${new Date().valueOf().toString()}` },
                });

                // finalize
                await finalize();

                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            } finally {
                finalizing.value = false;
            }
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<ExternalMqttHarvesterConfiguration>) => {
                taskRef.value = newTask;
            },
        );

        return {
            taskRef,
            sample,
            sampleFile,
            showFinalizeModal,
            finalLoading,
            currentStep,
            wizardActions,
            isFinalized,
            inUpdateStatus,
            pipelineFinalized,
            loadingFinalization,
            finalSample,
            endDateChanged,
            saveTask,
            setUploadedSample,
            setSampleFile,
            finalizeTask,
            modifyFinalSample,
            modifySample,
            setSelectedItems,
            setParameterName,
        };
    },
});
