


























import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ApolloTaskShell } from '../../../components';
import { useApolloTask, usePolicyFileUpload } from '../../../composable';
import {
    ApolloTask,
    HarvesterStep,
    InternalApiHarvesterConfiguration,
    TFile,
    TaskStep,
    WizardAction,
} from '../../../types';

export default defineComponent({
    name: 'InternalApiHarvester',
    props: {
        task: {
            type: Object as PropType<ApolloTask<InternalApiHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { ApolloTaskShell },
    setup(props, { root }) {
        const showFinalizeModal = ref<boolean>(false);
        const currentStep = ref<HarvesterStep>(HarvesterStep.Setup);
        const sampleFile = ref<TFile | null>(null);
        const sample = ref<any>(props.task.processedSample?.length ? props.task.processedSample : null);

        const retrieveURL = `${process.env.VUE_APP_BACKEND_URL}/api/retrieval/RETRIEVAL-QUERY-ID/file/UPLOADED-FILE-ID`;

        const { uploadSampleFileFromData } = usePolicyFileUpload(props.task.pipeline.id);
        const taskRef = ref(props.task);
        const {
            loading: loadingTask,
            isFinalized,
            inDraftStatus,
            finalize,
            save,
        } = useApolloTask<InternalApiHarvesterConfiguration>(taskRef);

        const finalLoading = computed(() => props.loading || loadingTask.value);

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: currentStep.value === HarvesterStep.Review,
            },
        ]);

        const setUploadedSample = (parsedSample: any) => {
            sample.value = parsedSample;
        };

        const setSampleFile = (file: TFile | null) => {
            sampleFile.value = file;
            if (!file) sample.value = null;
        };

        const finalizeTask = async () => {
            if (!taskRef.value) return;

            try {
                // save configuration and sample
                taskRef.value.configuration.sample = R.is(Array, sample.value) ? sample.value : [sample.value];
                await save();

                // upload sample to minio
                await uploadSampleFileFromData({
                    data: JSON.stringify(sample.value),
                    name: 'sample.json',
                    type: 'application/json',
                    policy: { folder: 'upload', subfolder: `sample/${new Date().valueOf().toString()}` },
                });

                // finalize
                await finalize();

                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            }
        };

        const addUploadedFile = () => {
            if (Array.isArray(sample.value))
                for (let i = 0; i < sample.value.length; i += 1) {
                    sample.value[i]['_uploaded_file'] = retrieveURL;
                }
            else sample.value['_uploaded_file'] = retrieveURL;
        };

        const removeUploadedFile = () => {
            if (Array.isArray(sample.value))
                for (let i = 0; i < sample.value.length; i += 1) {
                    if (Object.keys(sample.value[i]).includes('_uploaded_file'))
                        delete sample.value[i]['_uploaded_file'];
                }
            else if (Object.keys(sample.value).includes('_uploaded_file')) {
                delete sample.value['_uploaded_file'];
            }
        };

        const nextTab = () => {
            if (!sample.value || !taskRef.value) return;

            if (taskRef.value.configuration.dataType === 'textBinary') addUploadedFile();
            else removeUploadedFile();

            currentStep.value += 1;
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<InternalApiHarvesterConfiguration>) => {
                taskRef.value = newTask;
            },
        );

        return {
            taskRef,
            sample,
            sampleFile,
            showFinalizeModal,
            finalLoading,
            currentStep,
            wizardActions,
            isFinalized,
            inDraftStatus,
            setUploadedSample,
            setSampleFile,
            finalizeTask,
            nextTab,
        };
    },
});
