





































































































































































































import { ButtonGroup, ConfirmModal } from '@/app/components';
import { useSchedule } from '@/app/composable';
import { ScheduleFrequency } from '@/app/constants';
import { CreateScheduleDTO, UpdateScheduleDTO } from '@/app/types';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { ExclamationCircleIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CronSchedule from './CronSchedule.vue';

export default defineComponent({
    name: 'ScheduleEditor',
    components: {
        ConfirmModal,
        ValidationProvider,
        ButtonGroup,
        CronSchedule,
        ValidationObserver,
        ExclamationCircleIcon,
    },
    props: {
        workflowId: {
            type: [String, Number],
            required: true,
        },
        schedule: {
            type: Object as PropType<ScheduleType>,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        differencesExist: {
            type: Boolean,
            default: false,
        },
        frameworkVersion: {
            type: String,
            default: null,
        },
        retrievalType: {
            type: String,
            default: 'periodic',
        },
        frequencies: {
            type: Array,
            default: () => ['hourly', 'daily', 'weekly', 'monthly'],
        },
        startDate: {
            type: [Date, Number],
            required: false,
        },
        defaultStartDate: {
            type: Boolean,
            default: false,
        },
        disableScheduleOnCreate: {
            type: Boolean,
            required: true,
        },
        saving: {
            type: Boolean,
            default: false,
        },
        frequency: {
            type: String,
            default: null,
        },
        batchable: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const previousRetrievalType = ref(props.retrievalType);
        const errorMessages = ref<string[]>([]);
        const { validate } = useSchedule();
        const showChangeScheduleModal = ref(false);
        const newFrequency = ref(null);
        const scheduleValidationRef = ref<any>(null);
        const editMode = ref<boolean>(false);
        const dateYesterday = computed(() => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        });
        const retrieveOnce = computed(() => props.retrievalType && props.retrievalType === 'once');

        const configuration: any = ref(null);
        const compareScheduleToBeCreated: any = ref(null);

        const scheduleStartDate = computed(() => props.startDate ?? new Date());

        const setEndDate = () => {
            configuration.value.endDate = new Date(R.clone(configuration.value.startDate).setUTCHours(23, 59, 59));
        };

        const updateSchedule = (schedule: ScheduleType) => {
            if (schedule) {
                configuration.value = R.clone(schedule);
                editMode.value = true;
            } else {
                configuration.value = {
                    frequency: props.frequency ? props.frequency : ScheduleFrequency.Daily,
                    startDate: props.defaultStartDate ? scheduleStartDate.value : null,
                    endDate: null,
                    schedule: '* * * * *',
                };
                if (retrieveOnce.value) setEndDate();
                compareScheduleToBeCreated.value = R.clone(configuration.value);
                editMode.value = false;
            }
            if (scheduleValidationRef.value !== null) {
                scheduleValidationRef.value.reset();
            }
        };

        const updateConfiguration = () => {
            if (props.error) {
                emit('reset-error');
            }

            if (editMode.value) {
                emit('changed-schedule', configuration.value);
            } else {
                if (JSON.stringify(configuration.value) !== JSON.stringify(compareScheduleToBeCreated.value)) {
                    emit('creation-differences', true);
                    emit('set-toggle-click-event');
                } else {
                    emit('creation-differences', false);
                }
            }
        };

        watch(
            () => props.schedule,
            (schedule: any) => updateSchedule(schedule),
        );
        updateSchedule(props.schedule);

        watch(
            () => configuration.value,
            () => updateConfiguration,
            { deep: true },
        );
        updateConfiguration();

        const addSchedule = () => {
            configuration.value.schedule = '* * * * *';
        };

        const changeFrequency = (frequency: any) => {
            configuration.value.schedule = '';
            configuration.value.frequency = frequency; // eslint-disable-line no-param-reassign
            showChangeScheduleModal.value = false;

            addSchedule();
        };

        const checkChangeSchedule = (frequency: any) => {
            if (configuration.value.schedule !== '') {
                newFrequency.value = frequency;
                showChangeScheduleModal.value = true;
            } else if (frequency !== configuration.value.frequency) {
                changeFrequency(frequency);
            }
        };

        const saveSchedule = async () => {
            if (!scheduleValidationRef.value) return;

            errorMessages.value = [];

            const valid = await scheduleValidationRef.value.validate();
            if (!valid) return;

            const cronSchedule = configuration.value.schedule.split(' ');
            const commonPayload = {
                startDate: configuration.value.startDate,
                endDate: configuration.value.endDate,
                frequency: props.frequency ?? configuration.value.frequency,
                minute: Number(cronSchedule[0]) >= 0 ? Number(cronSchedule[0]) : null,
                hour: Number(cronSchedule[1]) >= 0 ? Number(cronSchedule[1]) : null,
                dayOfMonth: Number(cronSchedule[2]) >= 0 ? Number(cronSchedule[2]) : null,
                month: Number(cronSchedule[3]) >= 0 ? Number(cronSchedule[3]) : null,
                dayOfWeek: Number(cronSchedule[4]) >= 0 ? Number(cronSchedule[4]) : null,
                configuration: props.frameworkVersion ? { frameworkVersion: props.frameworkVersion } : {},
                entityId: props.workflowId,
                entityType: 'Workflow',
                isEnabled: true,
            };

            const scheduleExtraValidation: any = validate(R.clone(commonPayload), retrieveOnce.value as boolean); // check if the day/ time chosen is not included in the start/ end dates

            if (scheduleExtraValidation && !scheduleExtraValidation.dateParameters && !scheduleExtraValidation.dates) {
                if (!retrieveOnce.value)
                    commonPayload.endDate = new Date(
                        dayjs(new Date(commonPayload.endDate).setUTCHours(23, 59, 59)).format(),
                    );

                if (editMode.value) {
                    const editPayload: UpdateScheduleDTO = {
                        ...commonPayload,
                        id: props.schedule.id,
                        isEnabled: props.schedule.isEnabled,
                    };
                    emit('update', editPayload);
                } else {
                    const createPayload: CreateScheduleDTO = {
                        ...commonPayload,
                        entityId: props.workflowId,
                        entityType: 'Workflow',
                        isEnabled: !props.disableScheduleOnCreate,
                    };

                    emit('save', createPayload);
                    // emit('set-toggle-click-event');
                    emit('creation-differences', false);
                }
            } else {
                Object.keys(scheduleExtraValidation).forEach((key: any) =>
                    scheduleExtraValidation[key] ? errorMessages.value.push(scheduleExtraValidation[key]) : null,
                );
            }
        };

        const cancelScheduleEditing = () => {
            // emit('creation-differences', false);
            emit('cancel');
        };

        watch(
            () => props.retrievalType,
            (retrieval: string | undefined | null) => {
                if (retrieval && previousRetrievalType.value !== retrieval) {
                    configuration.value = {
                        frequency: props.frequency ? props.frequency : ScheduleFrequency.Daily,
                        startDate: null,
                        endDate: null,
                        schedule: '* * * * *',
                    };
                    editMode.value = false;
                    errorMessages.value = [];
                }
            },
        );

        watch(
            () => props.retrievalType,
            (newRetrievalType, oldRetrievalType) => {
                if (newRetrievalType && oldRetrievalType && newRetrievalType !== oldRetrievalType) {
                    previousRetrievalType.value = newRetrievalType;
                }
            },
        );

        // reset error messages if a field of the schedule config. changes
        watch(
            () => configuration.value,
            (scheduleConfig: any) => {
                if (
                    scheduleConfig &&
                    scheduleConfig.startDate &&
                    scheduleConfig.endDate &&
                    scheduleConfig.schedule.match(/[1-9]/g)
                ) {
                    errorMessages.value = [];
                }
            },
            { deep: true },
        );

        const calculateAfterStartDate = computed(
            () => new Date(dayjs(new Date(configuration.value.startDate)).format()),
        );

        const calculateBeforeEndDate = computed(() => {
            if (configuration.value.endDate && !retrieveOnce.value) {
                return new Date(dayjs(new Date(configuration.value.endDate)).format());
            }
            return null;
        });

        const saveButtonLabel = computed(() =>
            editMode.value
                ? `Updat${props.saving ? 'ing' : 'e'}`
                : props.batchable
                ? 'Add'
                : `Sav${props.saving ? 'ing' : 'e'}`,
        );

        return {
            configuration,
            showChangeScheduleModal,
            scheduleStartDate,
            addSchedule,
            newFrequency,
            checkChangeSchedule,
            dateYesterday,
            changeFrequency,
            emit,
            saveSchedule,
            editMode,
            scheduleValidationRef,
            cancelScheduleEditing,
            retrieveOnce,
            setEndDate,
            errorMessages,
            calculateAfterStartDate,
            calculateBeforeEndDate,
            saveButtonLabel,
        };
    },
});
