































import { useApolloTask, usePolicyFileUpload } from '@/modules/apollo/composable';
import { KafkaProcessingOptions, TaskStatus } from '@/modules/apollo/constants';
import { ApolloTask, ExternalKafkaHarvesterConfiguration, TaskStep, TFile, WizardAction } from '@/modules/apollo/types';
import { ScheduleAPI } from '@/modules/workflow-designer/api';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { computed, defineComponent, PropType, Ref, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ApolloTaskShell } from '../../../components';

export default defineComponent({
    name: 'ExternalKafkaHarvester',
    components: { ApolloTaskShell },
    props: {
        task: {
            type: Object as PropType<ApolloTask<ExternalKafkaHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
    },
    setup(props, { root }) {
        const taskRef = ref<ApolloTask<ExternalKafkaHarvesterConfiguration>>(props.task);

        const {
            loading: loadingTask,
            save,
            finalize,
            isFinalized,
            inDraftStatus,
            shouldClearHarvesterProcessedSample,
        } = useApolloTask(taskRef);
        const { uploadPolicyFiles, uploadSampleFileFromData } = usePolicyFileUpload(props.task.pipeline.id);

        const currentStep = ref<number>(0);
        const showFinalizeModal = ref<boolean>(false);
        const sample: Ref<any> = ref<any>(props.task.processedSample);
        const sampleFile = ref<TFile | null>(null);
        const finalSample = ref<any>(props.task.processedSample);
        const schedules = ref<ScheduleType[]>([]);

        const loading = computed(() => loadingTask.value);

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled:
                    props.task?.status !== TaskStatus.Deprecated &&
                    currentStep.value === 1 &&
                    !!props.task?.configuration.response.selectedItems.length,
            },
        ]);

        const setSample = (sampleData: any) => {
            sample.value = sampleData;
        };

        const setSampleFile = (file: TFile | null) => {
            sampleFile.value = file;
            if (!file) sample.value = null;
        };

        const setFinalSample = (updatedFinalSample: any) => {
            finalSample.value = updatedFinalSample;
        };

        const resetConnectionDetails = () => {
            Object.assign(taskRef.value.configuration.connectionDetails, { username: null, password: null });
        };

        const updateProcessingOption = (newProcessingOption: KafkaProcessingOptions) => {
            taskRef.value.configuration.processing = newProcessingOption;
            if (props.task.configuration.retrieval) taskRef.value.configuration.retrieval.endDate = null;
        };

        const setSelectedItems = (selected: string[]) => {
            taskRef.value.configuration.response.selectedItems = selected;
        };

        const updateSchedules = (updatedSchedules: ScheduleType[]) => {
            schedules.value = updatedSchedules;
        };

        const saveTask = async () => {
            try {
                if (finalSample.value) {
                    const arraySample = R.is(Array, finalSample.value) ? finalSample.value : [finalSample.value];
                    props.task.configuration.sample = arraySample;
                }
                const shouldClearProcessedSample = await shouldClearHarvesterProcessedSample();
                await save(shouldClearProcessedSample);
            } catch (e) {
                (root as any).$toastr.e('Failed to save task', 'An error occurred!');
                throw e;
            }
        };

        const finalizeTask = async () => {
            try {
                await saveTask();
                if (sampleFile.value) {
                    await uploadPolicyFiles([
                        {
                            file: sampleFile.value,
                            sample: true,
                            policy: {
                                folder: 'upload',
                                subfolder: `sample/${new Date().valueOf().toString()}`,
                            },
                        },
                    ]);
                } else {
                    await uploadSampleFileFromData({
                        data: JSON.stringify(finalSample.value),
                        name: 'sample.json',
                        type: 'application/json',
                        policy: {
                            folder: 'upload',
                            subfolder: `sample/${new Date().valueOf().toString()}`,
                        },
                    });
                }
                if (inDraftStatus.value && schedules.value.length) await ScheduleAPI.create(schedules.value);
                await finalize();
                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            }
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<ExternalKafkaHarvesterConfiguration>) => {
                taskRef.value = newTask;
            },
        );

        return {
            taskRef,
            currentStep,
            loading,
            showFinalizeModal,
            wizardActions,
            sample,
            sampleFile,
            schedules,
            finalSample,
            setSample,
            setSampleFile,
            setFinalSample,
            saveTask,
            finalizeTask,
            resetConnectionDetails,
            updateProcessingOption,
            setSelectedItems,
            updateSchedules,
        };
    },
});
