

















































import { HtmlModal, SvgImage } from '@/app/components';
import store from '@/app/store';
import { ArrowCircleRightIcon, CheckCircleIcon } from '@vue-hero-icons/outline';
import { PropType, defineComponent, onMounted, ref } from '@vue/composition-api';
import { ApolloTask } from '../../types';

export default defineComponent({
    name: 'TaskCompletionModal',
    components: { SvgImage, HtmlModal, CheckCircleIcon, ArrowCircleRightIcon },
    props: {
        task: {
            type: Object as PropType<ApolloTask>,
            required: true,
        },
        message: {
            type: String,
            default: null,
        },
        restartedTask: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const nextTaskRef = ref<any>(null);
        const backRef = ref<any>(null);

        onMounted(() => {
            if (nextTaskRef.value) nextTaskRef.value.$el.focus();
            else if (backRef.value) backRef.value.$el.focus();
        });

        return { nextTaskRef, backRef, store };
    },
});
