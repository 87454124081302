








































































































import { ConfirmButton, CopyToClipboardButton } from '@/app/components';
import { useFilters } from '@/app/composable';
import { WorkflowType } from '@/app/constants';
import { ChevronDownIcon, ExclamationCircleIcon, XCircleIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';
import { useTriggerSource } from '../../composable';
import { ExecutionFrameworkWrapper, ExecutionStatus } from '../../constants';
import { ExecutionHistoryEntry, ExecutionLog } from '../../types';
import ExecutionLogs from './ExecutionLogs.vue';

dayjs.extend(utc);

export default defineComponent({
    name: 'ExecutionOverview',
    components: {
        ConfirmButton,
        ExecutionLogs,
        XCircleIcon,
        ExclamationCircleIcon,
        ChevronDownIcon,
        CopyToClipboardButton,
    },
    props: {
        execution: {
            type: Object as PropType<ExecutionHistoryEntry>,
            required: true,
        },
        isCollapsed: {
            type: Boolean,
            default: false,
        },
        executionLogs: {
            type: Array as PropType<ExecutionLog[]>,
            default: () => [],
        },
        loadingLogs: {
            type: Boolean,
            default: false,
        },
        workflowType: {
            type: String as PropType<WorkflowType>,
            required: true,
            validator: (value: string) => (Object.values(WorkflowType) as string[]).includes(value),
        },
    },
    setup(props, { emit }) {
        const collapsed = ref(false);
        const { formatDateAs: formatDate } = useFilters();
        const { label: getTriggerSourceLabel } = useTriggerSource();

        const disabledCanceling = ref<boolean>(false);

        const bgColour = computed(() => {
            switch (props.execution.status) {
                case ExecutionStatus.Completed:
                    return 'text-white bg-green-800';
                case ExecutionStatus.Failed:
                    return 'text-white bg-red-600';
                case ExecutionStatus.Pending:
                    return 'text-white bg-slate-500';
                case ExecutionStatus.Running:
                    return 'text-white bg-slate-700';
                case ExecutionStatus.Cancelled:
                    return 'text-white bg-orange-800';
                case ExecutionStatus.Skipped:
                    return 'text-white bg-neutral-600';
                default:
                    return 'text-white bg-slateblue-700';
                // do nothing
            }
        });

        const executionTimestamp = computed(() => {
            return `${formatDate(props.execution.createdAt, true, 'DD/MM/YYYY')} at ${formatDate(
                props.execution.createdAt,
                true,
                'HH:mm',
                'UTC',
            )}`;
        });

        const setCollapsed = () => {
            if (props.isCollapsed) {
                collapsed.value = false;
                emit('deselect');
            } else {
                emit('select');
            }
        };

        const hasFileMetrics = computed(
            () =>
                props.execution?.metrics?.length === 1 &&
                !R.isNil(props.execution.metrics[0].totalFiles) &&
                !R.isNil(props.execution.metrics[0].totalProcessedFiles) &&
                props.execution.metrics[0].totalFiles > 0,
        );
        const totalFiles = computed(() => props.execution?.metrics[0]?.totalFiles);
        const totalProcessedFiles = computed(() => props.execution?.metrics[0]?.totalProcessedFiles);

        const modifiedMessage = computed(() => {
            switch (props.execution.status) {
                case ExecutionStatus.Completed:
                    if (hasFileMetrics.value && totalFiles.value > 1) {
                        if (totalFiles.value === totalProcessedFiles.value) {
                            return 'All files have been processed successfully';
                        } else {
                            return `${totalProcessedFiles.value} of ${totalFiles.value} files have been processed successfully`;
                        }
                    }
                    return 'All tasks have been executed successfully';
                case ExecutionStatus.Failed:
                    if (hasFileMetrics.value && totalProcessedFiles.value === 0)
                        return 'All files failed to be processed';
                    return 'The execution has failed to execute';
                case ExecutionStatus.Queued:
                    return 'The execution is in queue';
                case ExecutionStatus.Cancelled:
                    return 'The execution has been cancelled';
                case ExecutionStatus.Running:
                    return 'The execution is running';
                case ExecutionStatus.Skipped:
                    return 'The execution was skipped';
                default:
                    return '';
            }
        });

        const executionStatus = computed(() => {
            if (props.isCollapsed && props.execution.status) {
                const status = props.execution.status.split('.'); // e.g. dcj.service.failed
                if (status[status.length - 1] === 'completed') {
                    return 'bg-primary-100';
                }
                return 'bg-slate-100';
            }
            return '';
        });

        const executionTriggerSource = computed(() =>
            !R.isNil(props.execution.triggerSource) ? getTriggerSourceLabel(props.execution.triggerSource) : undefined,
        );

        const frameworkDetails = computed(() =>
            props.execution.executionFramework
                ? ExecutionFrameworkWrapper.find(props.execution.executionFramework)
                : null,
        );

        watch(
            () => props.isCollapsed,
            (newIsCollapsed: boolean) => {
                collapsed.value = newIsCollapsed;
            },
            { immediate: true },
        );

        return {
            collapsed,
            bgColour,
            executionTimestamp,
            frameworkDetails,
            setCollapsed,
            modifiedMessage,
            emit,
            executionStatus,
            ExecutionStatus,
            disabledCanceling,
            executionTriggerSource,
            WorkflowType,
        };
    },
});
