var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',{staticClass:"rounded-md shadow-sm sm:ml-3"},[(_vm.showCancel)?_c('button',{staticClass:"inline-flex items-center px-4 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out bg-white border rounded-md disabled:opacity-50 text-neutral-700 border-neutral-300 hover:text-neutral-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-neutral-800 active:bg-neutral-50 disabled:cursor-not-allowed",attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){_vm.showConfirmCancelModal = true}}},[_c('XIcon',{staticClass:"w-5 h-5 mr-2 -ml-1 text-neutral-500"}),_vm._v(" Cancel ")],1):(_vm.backTo)?_c('button',{staticClass:"inline-flex items-center px-4 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out bg-white border rounded-md disabled:opacity-50 text-neutral-700 border-neutral-300 hover:text-neutral-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-neutral-800 active:bg-neutral-50",attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.cancel}},[_c('ChevronLeftIcon',{staticClass:"w-5 h-5 mr-2 -ml-1 text-neutral-500"}),_vm._t("go-back",function(){return [_vm._v("Go back")]})],2):_vm._e()]),_vm._l((_vm.actions),function(action){return [(action.show)?_c('button',{key:action.key,staticClass:"inline-flex items-center px-4 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out border rounded-md focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed",class:{
                    'text-white border-transparent bg-primary-600 hover:bg-primary-500 focus:ring-primary focus:border-primary-700 active:bg-primary-700':
                        action.color === 'primary',
                    'bg-white text-primary-700 border-primary-500 hover:text-primary-500 focus:ring-blue focus:border-blue-300 active:text-primary-800 active:bg-primary-50':
                        action.color === 'white',
                    'text-green-700 border-green-500 hover:text-green-500 focus:ring-green focus:border-green-300 active:text-green-800 active:bg-green-50':
                        action.color === 'green',
                    'text-neutral-700 border-neutral-300 hover:text-neutral-500 bg-white':
                        action.color === 'neutral',
                },attrs:{"type":"button","disabled":!action.enabled || _vm.disabled},on:{"click":function($event){return _vm.$emit('action', action.key)}}},[_c(action.icon,{tag:"component",staticClass:"w-5 h-5 mr-2 -ml-1",class:{ 'text-neutral-500': action.color === 'neutral' }}),_vm._v(" "+_vm._s(action.label)+" ")],1):_vm._e()]})],2),_c('portal',{attrs:{"to":"modals"}},[(_vm.showConfirmCancelModal)?_c('ConfirmModal',{attrs:{"title":"Cancel changes","message":"Are you sure you want to cancel? Doing so will discard any changes made since the last save!","okButtonText":"Discard changes"},on:{"cancel":function($event){_vm.showConfirmCancelModal = false},"ok":_vm.cancel}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }