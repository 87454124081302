






































































import { ExclamationIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { ApolloTaskShell } from '../..';
import { useDatabaseHarvester } from '../../../composable';
import { ApolloTask, HarvesterStep, SAPHanaHarvesterConfiguration, TaskStep, WizardAction } from '../../../types';

export default defineComponent({
    name: 'SAPHanaHarvester',
    props: {
        task: {
            type: Object as PropType<ApolloTask<SAPHanaHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { ApolloTaskShell, ExclamationIcon },
    setup(props, { root, emit }) {
        const taskRef = ref(props.task);

        const {
            isFinalized,
            currentStep,
            scheduleDetailsDefined,
            invalidParams,
            errorAlert,
            loadingTask,
            isOwner,
            inDraftStatus,
            inUpdateStatus,
            isRunning,
            pipelineFinalized,
            schedules,
            loadingSampleRun,
            hasAnonymisation,
            showFinalizeModal,
            hasCompleted,
            sampleWithoutAdditional,
            sampleFields,
            taskStructure,
            queryIncludesModification,
            queryIncludesForbiddenTerms,
            parsedQuery,
            sqlQueryPreview,
            clearProcessedSample,
            runOnSample,
            nextTab,
            finalizeTask,
            checkSchedulesAndProceed,
        } = useDatabaseHarvester(taskRef, root, emit);

        const finalLoading = computed(() => props.loading || loadingTask.value);

        const connectionDetailsDefined = computed(
            () =>
                !!taskRef.value.configuration.connection.host &&
                !!taskRef.value.configuration.connection.port &&
                !!taskRef.value.configuration.connection.username &&
                !!taskRef.value.configuration.connection.password,
        );

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'sample-run',
                show:
                    !isFinalized.value &&
                    currentStep.value === HarvesterStep.Setup &&
                    !taskRef.value.processedSample?.length,
                enabled:
                    !finalLoading.value &&
                    connectionDetailsDefined.value &&
                    (!!taskRef.value.configuration.query ||
                        (!!taskRef.value?.configuration.table && !!taskRef.value?.configuration.databaseSchema)) &&
                    scheduleDetailsDefined.value,
            },
            {
                key: 'view-processed-sample',
                show:
                    !isFinalized.value &&
                    currentStep.value === HarvesterStep.Setup &&
                    !!taskRef.value.processedSample?.length,
                enabled: scheduleDetailsDefined.value,
            },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled:
                    !finalLoading.value &&
                    currentStep.value === HarvesterStep.Review &&
                    scheduleDetailsDefined.value &&
                    !invalidParams.value &&
                    !!taskRef.value.configuration.identifierColumn,
            },
        ]);

        if (inUpdateStatus.value && !connectionDetailsDefined.value) clearProcessedSample();

        return {
            currentStep,
            errorAlert,
            wizardActions,
            loadingTask,
            taskRef,
            finalLoading,
            isOwner,
            inDraftStatus,
            isRunning,
            pipelineFinalized,
            isFinalized,
            schedules,
            scheduleDetailsDefined,
            runOnSample,
            nextTab,
            loadingSampleRun,
            hasAnonymisation,
            clearProcessedSample,
            finalizeTask,
            showFinalizeModal,
            hasCompleted,
            sampleWithoutAdditional,
            sampleFields,
            taskStructure,
            queryIncludesModification,
            queryIncludesForbiddenTerms,
            parsedQuery,
            sqlQueryPreview,
            checkSchedulesAndProceed,
        };
    },
});
