












































































































































































































































































































































































































































































































































































import { HtmlModal, JsonEditor, Scrollbar, TwSelect } from '@/app/components';
import { useModelBrowser } from '@/app/composable';
import { dateFormats, datetimeFormats, minimalTimezoneSet as timezoneSet, unitTransformations } from '@/app/constants';
import { Concept } from '@/app/interfaces';
import {
    alphanumericValidator,
    maxLengthValidator,
    rejectReservedValidator,
    requiredValidator,
} from '@/app/validators';
import { XIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { AlternateNaming } from '../../constants';

extend('required', requiredValidator);
extend('max', maxLengthValidator);
extend('alphanumeric', alphanumericValidator);
extend('rejectReserved', rejectReservedValidator);

export default defineComponent({
    name: 'MappingDetails',
    components: {
        Scrollbar,
        TwSelect,
        JsonEditor,
        HtmlModal,
        XIcon,
        ValidationObserver,
        ValidationProvider,
        ExclamationIcon,
    },
    model: {
        prop: 'selectedFields',
        event: 'change',
    },
    props: {
        selectedFields: {
            type: Array,
            required: true,
        },
        parentConcept: {
            type: Object,
            required: false,
        },
        customizedConcepts: {
            type: Object,
            required: true,
        },
        countMultiple: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        basePath: {
            type: Array,
            required: false,
            default: () => [],
        },
        validationErrors: {
            type: Object as PropType<
                Record<number, { message: string | null; description?: string | null; type?: string; title?: string }>
            >,
            required: true,
        },
        alternateNaming: {
            type: String,
            required: false,
        },
    },
    setup(props, { emit }) {
        const formRef = ref<any>();
        const referenceConcept = ref<any>(null);
        const referencePrefix = ref<string | null>(null);
        const referenceConceptDescription = ref<string | null>(null);
        const customPrefix = ref<{ prefix: string; description: string }>({ prefix: '', description: '' });
        const showExampleStructureModal = ref<boolean>(false);

        const { getConceptFromId } = useModelBrowser();

        const isSelectionValid = computed(() =>
            props.selectedFields.reduce(
                (result: boolean, field: any) => field.target.id === null && result,
                !!props.parentConcept,
            ),
        );

        const concept: any = computed(() => props.selectedFields[0]);

        const prefixRules = computed(() => {
            if (referencePrefix.value === 'custom')
                return {
                    required: true,
                    alphanumeric: true,
                    rejectReserved: [reservedPrefixes.value],
                    max: 15,
                };
            return {};
        });

        const oneElementArraysOptions = computed(() => {
            if (!concept.value) return [];
            return concept.value.target.path
                .filter((field: string) => field.includes('[]'))
                .map((field: string, i: number) => ({ name: field, index: i }));
        });

        const userPrefixes = computed(() => {
            if (props.parentConcept && R.hasPath([props.parentConcept.id], props.customizedConcepts))
                return props.customizedConcepts[props.parentConcept.id];
            return [];
        });

        const reservedPrefixes = computed(() => {
            let allPrefixes: { prefix: string }[] = [];
            if (referenceConcept.value?.referencePrefix) allPrefixes = [...referenceConcept.value.referencePrefix];
            if (userPrefixes.value) allPrefixes = [...allPrefixes, ...userPrefixes.value];
            return allPrefixes.map((p: { prefix: string }) => p.prefix);
        });

        const relatedConceptName = computed(() => {
            const referenceConceptObj: Concept = getConceptFromId(referenceConcept.value.referenceConceptId);
            const referenceConceptName =
                referenceConceptObj.name.charAt(0).toUpperCase() + referenceConceptObj.name.slice(1);

            const prefixName = referencePrefix.value === 'custom' ? customPrefix.value.prefix : referencePrefix.value;

            if (referenceConcept.value.metadata?.multiple) return `${prefixName}${referenceConceptName}[]`;
            return `${prefixName}${referenceConceptName}`;
        });

        const relatedConcepts: any = computed(() => {
            if (!props.parentConcept) return [];
            return R.sortWith([R.ascend(R.prop('name'))])(
                props.parentConcept.children.filter((obj: any) => obj.type === 'object'),
            );
        });

        const removeArrayBracketsFromBasePath = (basePath: string[] | undefined, field: string[]): string[] => {
            if (!basePath || field.length < basePath.length) return field;
            const newField = R.clone(field);
            for (let i = 0; i < basePath.length; i++)
                if (newField[i].replaceAll('[]', '') === basePath[i]) newField[i] = newField[i].replaceAll('[]', '');
                else break;
            return newField;
        };

        const oneElementArrays = computed(() => {
            if (!concept.value) return 0;
            const arraysInTarget = concept.value.target.path.join('')?.match(/\[\]/g)?.length ?? 0;
            const arraysInSource =
                removeArrayBracketsFromBasePath(props.basePath as string[], concept.value.source.path)
                    ?.join('')
                    ?.match(/\[\]/g)?.length ?? 0;

            return arraysInTarget - arraysInSource;
        });

        const sampleValues: any = {
            string: 'sample',
            integer: 123,
            double: 123.4,
            number: 123.4,
            datetime: '2020-02-20 00:00:00',
            date: '2020-02-20',
            time: '00:00:00',
            boolean: true,
        };

        /**
         * Generate example structure of the field data after mapping
         *
         * For each array parent in target which has not been selected to be a one-element-array - add 2 children elements,
         * otherwise only 1, in order to create a structure that has multiple elements for each non-one-element array
         *
         * Ths makes use of Ramdas' assocPath: https://ramdajs.com/docs/#assocPath
         */
        const generateExampleStructure = computed(() => {
            if (
                concept.value?.transformation?.oneElementArrays &&
                oneElementArrays.value - concept.value.transformation.oneElementArrays.length === 0
            ) {
                const assocPath: any[] = concept.value.target.path.flatMap((field: string) =>
                    field.includes('[]') ? [field.slice(0, field.length - 2), 0] : [field],
                );

                const actualArraysIndex = assocPath.reduce(
                    (prev, curr, index) => {
                        if (curr === 0) {
                            if (!concept.value.transformation.oneElementArrays.includes(prev[0])) {
                                return [prev[0] + 1, [...prev[1], index]];
                            } else {
                                return [prev[0] + 1, prev[1]];
                            }
                        } else {
                            return prev;
                        }
                    },
                    [0, []],
                )[1];

                const exampleValue = sampleValues[concept.value.target.type] || null;
                let exampleStructure = R.assocPath(assocPath, { [concept.value.target.title]: exampleValue }, {});
                const sourceArrays = concept.value.source.path.filter((p: string) => p.includes('[]')).length;
                for (let i = 0; i < sourceArrays; i++) {
                    assocPath[actualArraysIndex[i]] = 1;
                    exampleStructure = R.assocPath(
                        assocPath,
                        { [concept.value.target.title]: exampleValue },
                        exampleStructure,
                    );
                    assocPath[actualArraysIndex[i]] = 0;
                }
                return JSON.stringify(exampleStructure, null, '\t');
            }
            return null;
        });

        const setConcept = async (predict: boolean) => {
            if (!(await formRef.value.validate())) return;
            if (referencePrefix.value === 'custom' && props.parentConcept) {
                let customizedConcepts = R.clone(props.customizedConcepts);
                if (!R.hasPath([props.parentConcept.id], customizedConcepts)) {
                    customizedConcepts = R.assocPath([props.parentConcept.id], [], customizedConcepts);
                }
                customizedConcepts[props.parentConcept.id].push(R.clone(customPrefix.value));

                emit('customized-concepts-changed', customizedConcepts);
            }

            const updatedFields = R.clone(props.selectedFields).map((field: any) => {
                if (!field.target.pathUids) field.target.pathUids = [];
                field.target.path.push(relatedConceptName.value);
                field.target.parentIds.push(referenceConcept.value.referenceConceptId);
                field.target.pathUids.push(referenceConcept.value.uid);
                field.target.categories.push(getConceptFromId(referenceConcept.value.referenceConceptId).name);
                return field;
            });
            emit('change', updatedFields);

            emit('concept-changed', referenceConcept.value.uid);
            referenceConcept.value = null;
            referencePrefix.value = null;
            customPrefix.value = { prefix: '', description: '' };

            if (predict) emit('predict');
        };

        const changedDateFormat = () => {
            if (
                concept.value?.target?.type === 'datetime' &&
                [
                    'ISO 8601',
                    'Unix Timestamp (nanoseconds)',
                    'Unix Timestamp (microseconds)',
                    'Unix Timestamp (milliseconds)',
                    'Unix Timestamp (seconds)',
                ].indexOf(concept.value.transformation.sourceDateFormat) > -1
            ) {
                concept.value.transformation.sourceTimezone = 'UTC';
            }
            emit('has-change');
        };

        const errors = computed(() => {
            if (concept.value?.temp?.invalid)
                return {
                    oneElementArrays:
                        oneElementArrays.value - concept.value?.transformation?.oneElementArrays?.length > 0,
                    sourceUnit: !concept.value?.transformation?.sourceUnit,
                    sourceDateFormat: !concept.value?.transformation?.sourceDateFormat,
                    sourceTimezone: !concept.value?.transformation?.sourceTimezone,
                    dateOrder:
                        concept.value?.transformation?.sourceDateFormat === 'infer' &&
                        !concept.value?.transformation?.dateOrder,
                    order: !concept.value?.transformation?.order,
                    alias: concept.value?.temp?.invalidRegex,
                };
            return {};
        });

        const showValidationError = computed(
            () =>
                errors.value &&
                concept.value?.temp?.invalid &&
                String(concept.value.source.id) in props.validationErrors &&
                props.validationErrors[String(concept.value.source.id)].description,
        );

        const sourceFormatWarning = computed(() => {
            if (!concept.value?.transformation?.sourceDateFormat) return null;
            switch (concept.value.transformation.sourceDateFormat) {
                case 'infer':
                    return 'Attention: Inference from data only works for datetime values in typical formats - it does not work for UNIX timestamps that should be explicitly selected.';
                case 'ISO 8601':
                    return 'Attention: If the datetime values in your data include nanoseconds, this option will not work.';
                default:
                    return null;
            }
        });

        return {
            formRef,
            concept,
            customPrefix,
            dateFormats,
            datetimeFormats,
            isSelectionValid,
            timezoneSet,
            referenceConcept,
            referenceConceptDescription,
            referencePrefix,
            relatedConcepts,
            setConcept,
            unitTransformations,
            userPrefixes,
            emit,
            changedDateFormat,
            oneElementArrays,
            oneElementArraysOptions,
            generateExampleStructure,
            showExampleStructureModal,
            errors,
            prefixRules,
            AlternateNaming,
            sourceFormatWarning,
            showValidationError,
        };
    },
});
