














































































































import { MonitoringAPI } from '@/app/api';
import { AlertBanner, ConfirmModal, ProcessedSampleView, TwButton, WizardTabs } from '@/app/components';
import { useAxios, useJsonObject, useSockets } from '@/app/composable';
import { WebSocketsEvents, WebSocketsRoomTypes } from '@/app/constants';
import store from '@/app/store';
import { computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref } from '@vue/composition-api';
import camelcaseKeys from 'camelcase-keys';
import { OrbitSpinner } from 'epic-spinners';
import { clone, equals, isEmpty } from 'ramda';
import { ApolloAPI } from '../api';
import { ApolloTaskShell } from '../components';
import CleaningConfiguration from '../components/cleaning/CleaningConfiguration.vue';
import CleaningReview from '../components/cleaning/CleaningReview.vue';
import { useApolloPipeline, useApolloTask, useSampleFields, useSampleRun } from '../composable';
import { HarvesterBlockId, PreprocessingBlockId, TaskExecutionStatus, TaskStatus } from '../constants';
import { ApolloTask, Stats, StatsPerField, TaskStats, WizardAction } from '../types';
import { CleaningConfigurationType, CleaningStep, CleaningTask, OutliersRuleType } from '../types/cleaning.type';

export default defineComponent({
    name: 'Cleaning',
    metaInfo() {
        return { title: `Cleaning${(this as any).job ? ` for: ${(this as any).job.name}` : ''}` };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    components: {
        CleaningConfiguration,
        CleaningReview,
        OrbitSpinner,
        TwButton,
        WizardTabs,
        ConfirmModal,
        ProcessedSampleView,
        ApolloTaskShell,
        AlertBanner,
    },
    setup(props, { root }) {
        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;

        const cleaning: CleaningTask = {
            blockId: PreprocessingBlockId.Cleaning,
            steps: [
                {
                    key: CleaningStep.Configuration,
                    name: 'Configuration',
                    component: CleaningConfiguration,
                    scrollable: false,
                },
                {
                    key: CleaningStep.SamplePreview,
                    name: 'Review Rules',
                    component: ProcessedSampleView,
                    scrollable: false,
                },
                { key: CleaningStep.Confirm, name: 'Confirm', component: CleaningReview, scrollable: false },
            ],
        };

        const currentStep = ref<CleaningStep>(CleaningStep.Configuration);
        const { loading, error: cleaningError, exec } = useAxios(true);

        const hasChanges = ref<boolean>(false);
        const showFinalizeModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const showOrderInformation = ref<boolean>(true);
        const task = ref<ApolloTask<CleaningConfigurationType> | undefined>();
        const selectedFieldIdx = ref<number | null>(null);
        const stats = ref<TaskStats<Stats<StatsPerField>> | null>(null);
        const problematicSampleConstraints = ref<StatsPerField[]>([]);
        const showEmptySampleModal = ref<boolean>(false);
        const editMode = ref<string | null>(null);

        const alternateNames = ref<Record<string, { path: string[]; title: string[] }> | null>(null);
        const showAlternateNaming = ref<boolean>(false);
        const showConfirmModal = ref<boolean>(false);
        const hasAlternateNames = computed(() => alternateNames.value && Object.keys(alternateNames.value).length);

        const { extractMappingFieldNames, extractAlternateNames } = useSampleFields();

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'sample-run',
                show: !isFinalized.value && currentStep.value === CleaningStep.Configuration && !processedSample.value,
                enabled: allowNext.value,
            },
            {
                key: 'view-processed-sample',
                show:
                    !isFinalized.value &&
                    currentStep.value === CleaningStep.Configuration &&
                    !!processedSample.value &&
                    !loadingSampleRun.value,
                enabled: true,
            },
            { key: 'save', show: currentStep.value === CleaningStep.Configuration, enabled: hasChanges.value },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: canFinalise.value,
            },
            { key: 'revise', label: 'Revise', show: canRevise.value, enabled: canRevise.value },
        ]);

        const hasChangesAfterRevise = computed(
            () => !!task.value?.configuration.hasChangesAfterRevise || hasChanges.value,
        );

        const allowNext = computed(() => {
            return isUnderRevise.value && currentStep.value === CleaningStep.Configuration
                ? (hasChangesAfterRevise.value || !problematicSampleConstraints.value.length) && !loading.value
                : !loading.value;
        });

        const currentStepInfo = computed(() => cleaning.steps.find((step) => step.key === currentStep.value));

        const {
            harvester,
            mapping,
            error: pipelineError,
            fetchPipeline,
            isStreaming,
            isLargeFilesHarvester,
            isFileHarvester,
            isAPIHarvester,
            isDatabaseHarvester,
            isPeriodicOrPolling,
            isFinalized: isPipelineFinalised,
            isUnderRevise,
            isOnPremise,
            hasAnonymisation,
        } = useApolloPipeline(props.id, undefined, false);

        const {
            canRevise,
            shouldUpdateAssetsAfterRevise,
            updateAssetsAfterRevise,
            isFinalized,
            reviseFields,
            initialiseNewField,
            inDraftStatus,
            inUpdateStatus,
        } = useApolloTask(task);

        const canFinalise = computed(() => currentStep.value === CleaningStep.Confirm && !isFinalized.value);

        const error = computed(() => cleaningError.value || pipelineError.value);

        const configuration = ref<CleaningConfigurationType>({
            fields: [],
        });

        const allowDropOrDefaultOnly = computed(() => {
            if (!harvester.value) return false;
            return (
                [
                    HarvesterBlockId.Api,
                    HarvesterBlockId.Kafka,
                    HarvesterBlockId.ExternalKafka,
                    HarvesterBlockId.InternalApi,
                    HarvesterBlockId.MQTT,
                    HarvesterBlockId.ExternalMQTT,
                ].includes(harvester.value.blockId as HarvesterBlockId) ||
                (isDatabaseHarvester(harvester.value.configuration) && (isPeriodicOrPolling() || isOnPremise.value)) ||
                (harvester.value.blockId === HarvesterBlockId.LargeFiles && !hasAnonymisation.value)
            );
        });

        const canRunManyTimes = computed(() => {
            if (!harvester.value) return false;
            if (isFileHarvester(harvester.value.configuration)) return false;
            if (isAPIHarvester(harvester.value.configuration) || isDatabaseHarvester(harvester.value.configuration)) {
                return isPeriodicOrPolling();
            }
            return true;
        });

        const updateProcessedSample = async (sampleData: any, sampleStats: any = null) => {
            task.value!.processedSample = sampleData;
            if (sampleData) {
                configuration.value.emptySample = false;
                next();
            } else {
                await save(false);
                // execution sends message in snake case so we need to transform it to camel case
                const statsCorrected: Stats<StatsPerField> = camelcaseKeys(sampleStats, { deep: true });
                if (statsCorrected.inputRecords > 0 && statsCorrected?.outputRecords === 0) {
                    problematicSampleConstraints.value = statsCorrected.statsPerField.filter(
                        (field) => field.dropped > 0,
                    );
                    showEmptySampleModal.value = true;
                } else {
                    problematicSampleConstraints.value = statsCorrected.statsPerField.filter(
                        (field) => !!field.errorCode,
                    );
                }
            }
        };

        const { loadingSampleRun, executeSampleRun, onMessage } = useSampleRun(task, root, updateProcessedSample);

        const fetch = async () => {
            await fetchPipeline();
            task.value = (await exec(ApolloAPI.getTask(props.id, 'cleaning')))?.data;
            configuration.value = task.value!.configuration;

            alternateNames.value = extractAlternateNames(mapping.value!.configuration);
            const mappedFields = mapping.value!.configuration.fields;

            // initialise fields on first load
            if (!configuration.value.fields.length) {
                configuration.value.fields = extractMappingFieldNames(mappedFields).map(initialiseNewField);
            } else {
                configuration.value.fields.forEach((field) =>
                    field.constraints.forEach((c) => {
                        if (!c.fieldName) c.fieldName = field.name;
                    }),
                );
            }

            // if there are invalid rules, remove them and clear processed sample
            if (allowDropOrDefaultOnly.value) {
                const fieldsBefore = clone(configuration.value.fields);
                for (const field of configuration.value.fields) {
                    field.constraints = field.constraints.filter((constraint) =>
                        [OutliersRuleType.DROP, OutliersRuleType.DEFAULT_VALUE].includes(
                            constraint.outliersRule?.type as OutliersRuleType,
                        ),
                    );
                }
                const fieldsAfter = clone(configuration.value.fields);
                if (!equals(fieldsBefore, fieldsAfter)) task.value!.processedSample = [];
            }

            // revise fields if task is revised
            if (task.value?.status === TaskStatus.Updating) {
                configuration.value.fields = reviseFields(mappedFields, configuration.value.fields);
            }

            // go to review tab if task already configured
            if (isFinalized.value) {
                currentStep.value = CleaningStep.Confirm;
            }

            // get stats
            // draft and streaming pipelines or pipelines executed with spark have no stats currently
            if (
                !isStreaming.value &&
                !inDraftStatus.value &&
                !isLargeFilesHarvester.value &&
                (!inUpdateStatus.value || isUnderRevise.value)
            ) {
                const res: TaskStats<Stats<StatsPerField>> = (
                    await exec(MonitoringAPI.taskStats(props.id, task.value!.id))
                )?.data;
                if (isPipelineFinalised.value) stats.value = res;
                const latestExecutionStats = res?.latestExecutionStats;

                problematicSampleConstraints.value = latestExecutionStats.statsPerField.filter(
                    (field) => !!field.errorCode || (latestExecutionStats?.outputRecords === 0 && field.dropped > 0),
                );
            }
        };

        const reviseTask = async () => {
            if (!task.value) return;
            try {
                exec(ApolloAPI.reviseTask(props.id, 'cleaning'))
                    .then(fetchPipeline)
                    .then(() => {
                        if (task.value) {
                            task.value.status = TaskStatus.Updating;
                            task.value.executionStatus = TaskExecutionStatus.Updating;
                            task.value.processedSample = [];
                            task.value.configuration.emptySample = false;
                            task.value.configuration.hasChangesAfterRevise = false;
                        }
                    });

                currentStep.value = CleaningStep.Configuration;
                (root as any).$toastr.s(
                    'The configuration of the cleaning step is now available for updates.',
                    'Success',
                );
            } catch (e) {
                (root as any).$toastr.e('Revising of the configuration of the cleaning step failed', 'Failed');
            }
        };

        const processedSample = computed(() => {
            if (hasChanges.value || isEmpty(task.value?.processedSample)) return null;
            return task.value?.processedSample;
        });

        const changeStep = async (step: CleaningStep) => {
            if (step < currentStep.value) previous();
            else if (step > currentStep.value) next();
        };

        const { getFixedJSON } = useJsonObject();
        const { extractFieldSample } = useSampleFields();

        const sample = computed(() => {
            if (!task.value?.inputSample) return [];
            return getFixedJSON(task.value.inputSample);
        });

        const selectedField = computed(() =>
            selectedFieldIdx.value !== null ? configuration.value.fields[selectedFieldIdx.value] : null,
        );

        const selectedFieldSample = computed(() => {
            if (!selectedField.value) return null;
            return {
                ...selectedField.value,
                sample: extractFieldSample(sample.value, selectedField.value.title, selectedField.value.path),
            };
        });

        const updateSampleFailedConstraints = (constraintId: number) => {
            problematicSampleConstraints.value = problematicSampleConstraints.value.filter(
                (constraint: any) => constraint.id !== constraintId,
            );
        };

        const configurationChanged = () => {
            hasChanges.value = true;
            configuration.value.emptySample = false;
        };

        const runOnSample = async () => {
            problematicSampleConstraints.value = [];
            await save(false);
            executeSampleRun();
        };

        const proceed = () => {
            editMode.value = null;
            showConfirmModal.value = false;
            next();
        };

        const next = () => {
            // if currently editing a constraints show confirmation to discard edits
            if (editMode.value) {
                showConfirmModal.value = true;
                return;
            }

            // if no processed sample in configuration page then sample run needs to be run
            if (currentStep.value === CleaningStep.Configuration && !processedSample.value) {
                runOnSample();
                return;
            }

            // proceed to next step
            currentStep.value += 1;
        };

        const previous = () => {
            currentStep.value -= 1;
        };

        const save = async (notify: boolean = true, clearProcessedSample = true) => {
            if (!task.value) return;
            try {
                if (isUnderRevise.value) configuration.value.hasChangesAfterRevise = hasChanges.value;
                await exec(ApolloAPI.updateTask(props.id, 'cleaning', configuration.value, clearProcessedSample));
                if (clearProcessedSample) task.value.processedSample = [];
                if (notify) (root as any).$toastr.s('Cleaning configuration saved successfully', 'Success');
                hasChanges.value = false;
            } catch (e) {
                (root as any).$toastr.e('Saving cleaning configuration failed', 'Failed');
                hasChanges.value = true;
                throw e;
            }
        };

        const finalize = async () => {
            if (!task.value) return;

            if (shouldUpdateAssetsAfterRevise()) {
                await updateAssetsAfterRevise();
                restartedStep.value = true;
            }
            await exec(ApolloAPI.finalizeTask(props.id, 'cleaning'));
            showFinalizeModal.value = true;
        };

        const cancel = () => {
            root.$router.push({ name: 'data-checkin-jobs', query: store.state.queryParams.jobs });
        };

        const unlockJob = async () => {
            await exec(ApolloAPI.unlock(props.id));
        };

        onMounted(async () => {
            window.addEventListener('beforeunload', unlockJob);
        });

        const { subscribe, unsubscribe, leaveSocketRoom } = useSockets();

        onMounted(async () => {
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
        });

        onUnmounted(async () => {
            unlockJob();
        });

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, props.id);
        });

        fetch();

        return {
            cancel,
            finalize,
            save,
            next,
            reviseTask,
            updateSampleFailedConstraints,
            configurationChanged,
            proceed,
            runOnSample,
            changeStep,
            isMacOS,
            cleaning,
            currentStep,
            currentStepInfo,
            CleaningStep,
            showOrderInformation,
            canRunManyTimes,
            configuration,
            hasChanges,
            isFinalized,
            selectedFieldIdx,
            wizardActions,
            TaskStatus,
            selectedFieldSample,
            loading,
            task,
            showFinalizeModal,
            hasAlternateNames,
            showAlternateNaming,
            alternateNames,
            error,
            showEmptySampleModal,
            loadingSampleRun,
            harvester,
            restartedStep,
            stats,
            sample,
            editMode,
            problematicSampleConstraints,
            showConfirmModal,
            allowNext,
            allowDropOrDefaultOnly,
        };
    },
});
